import React from 'react'
import { Container } from './styles'

function ContactButton() {
  return (
    <Container href='https://api.whatsapp.com/send?phone=5519993807111&text=Ol%C3%A1.%20Quero%20conhecer%20a%20escola!' target='blank'>
        contato
    </Container>
  )
}

export default ContactButton